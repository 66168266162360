import { TextField } from "@mui/material"

interface Props {
  name: string
  label: string
  value: string
  error: string
  required?: boolean
  disabled?: boolean
  mt?: number
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}
const PasswordTextField = ({
  name,
  label,
  value,
  error,
  handleChange,
  required = false,
  disabled = false,
  mt = 2,
}: Props) => {
  return (
    <TextField
      id={name}
      name={name}
      type="password"
      variant="standard"
      value={value}
      label={label}
      size="small"
      sx={{ mt: mt }}
      onChange={handleChange}
      error={!!error}
      helperText={error}
      required={required}
      disabled={disabled}
      fullWidth
    />
  )
}
export default PasswordTextField
